import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Widget, { Color, FontSize } from "../Components/Widget";
import * as SvgIcon from "../Components/SvgIcon";
import * as Ant from "antd";
import { navigate } from "gatsby";
import "../index.css";
import chtLogo from "../images/CHTLogo_color.svg";
import _ from "lodash";

const queryString = require("query-string");
const countDonwSec = 30; // 倒數秒數
const chtUrl = "https://www.cht.com.tw"; // 跳轉網址

/**
 * 填答完成錯誤頁面
 * 依據問卷錯誤 code 呈現不同文案
 * @return {ErrorPage}
 */
function ErrorPage({ location }) {
  const [loading, setLoading] = useState(false);

  const [sec, setSec] = useState(countDonwSec);

  const { code, message, query, locationSearch } = useMemo(() => {
    const locationSearch = _.get(location, "search");
    const query = queryString.parse(locationSearch);
    const code = _.get(query, "code", "400");
    const message = _.get(query, "message", "似乎遇到錯誤...");
    console.log("useMemo");
    return { code, message, query, locationSearch };
  }, [location]);

  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      } else {
        clearInterval(interval);
        relocation();
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  const relocation = () => {
    const preview = _.get(query, "preview");
    if (preview) {
      const id = _.get(query, "id");
      top.location.replace(`/preview?id=${id}`);
    }

    if (locationSearch) navigate(`/q${locationSearch}`);
  };

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          zIndex: "999999",
        }}
      >
        <Ant.Spin />
      </div>
    );
  }

  return (
    <Wrapper>
      <Main>
        <div className="content">
          <div className="absolute bar" />

          <div className="absolute img">
            <img src={require("../images/404_error_picture.png")} alt=""></img>
          </div>
          <div className="absolute hint">{code}</div>
          <div className="absolute t1">{message}</div>
          <div className="absolute t2">預計</div>
          <div className="absolute sec">{sec}</div>
          <div className="absolute t3">秒後導回問卷畫面</div>
          <div className="absolute btn" onClick={() => relocation()}>
            回到問卷
            <div className="absolute arrow">
              <SvgIcon.KeyboardBackspace color={Color.ChtBlue_5} />
            </div>
          </div>
        </div>
      </Main>
      <Footer>
        <img src={chtLogo} alt="中華電信" />
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  // index.css 的 font-family 被 less.css 蓋掉
  // 所以在各別 Component 宣告一次
  font-family: "Helvetica Neue", "Noto Sans TC", "sans-serif";
  -ms-overflow-style: none;
  width: 800px;
  padding-top: 40px;
  margin: 0 auto;
  @media screen and (max-width: 823px) {
    width: 338px;
  }
`;

const Main = styled.div`
  .content {
    width: 100%;
    height: 500px;
    padding: 0 0 16px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    background-color: ${Color.GreyWhite};
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .bar {
    width: 100%;
    height: 5px;
    border-radius: 5px 5px 0px 0px;
    background-image: linear-gradient(
      to right,
      ${Color.ChtBlue_5} 0%,
      ${Color.ChtDBlue_5} 20%,
      ${Color.CHTMOrange_5} 39%,
      ${Color.CHTLOrange_5} 60%,
      ${Color.CHTMGreen_5} 81%,
      ${Color.CHTLGreen_5} 100%
    );
  }

  .hint {
    top: 115px;
    left: 114px;
    font-size: 140px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: ${Color.ChtBlue_6};
    width: 218px;
    height: 100px;
  }

  .img {
    top: 95px;
    left: 435px;
    width: 300px;
    height: 300px;
    background-color: rgba(109, 190, 255, 0);
    img {
      width: 300px;
      height: 300px;
    }
  }

  .t1 {
    top: 244px;
    left: 114px;
    width: 200px;
    height: 28px;
    font-size: ${FontSize.title}px;
  }

  .t2 {
    top: 280px;
    left: 114px;
    width: 40px;
    height: 28px;
    font-size: ${FontSize.title}px;
  }

  .t3 {
    top: 280px;
    left: 217px;
    width: 160px;
    height: 28px;
    font-size: ${FontSize.title}px;
  }

  .sec {
    top: 280px;
    left: 166px;
    width: 34px;
    height: 28px;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.1;
  }

  .btn {
    top: 340px;
    left: 114px;
    width: 128px;
    height: 40px;
    background-color: rgba(109, 190, 255, 0.2);
    font-size: ${FontSize.head}px;
    color: #209cff;
    padding: 8px 16px;
    cursor: pointer;

    .arrow {
      top: 9px;
      left: 90px;
    }
  }

  @media screen and (max-width: 823px) {
    .content {
      height: 491px;
    }

    .hint {
      top: 225px;
      left: 107px;
      font-size: 80px;
      line-height: 1.1;
      width: 125px;
      height: 80px;
    }

    .img {
      top: 27px;
      left: 24px;
      width: 292px;
      height: 292px;
      img {
        width: 292px;
        height: 292px;
      }
    }

    .t1 {
      top: 331px;
      left: 112px;
      font-size: ${FontSize.head}px;
      width: 150px;
      height: 24px;
    }

    .t2 {
      top: 363px;
      left: 62px;
      font-size: ${FontSize.head}px;
      width: 32px;
      height: 24px;
    }

    .t3 {
      top: 363px;
      left: 147px;
      font-size: ${FontSize.head}px;
      width: 128px;
      height: 24px;
    }

    .sec {
      top: 363px;
      left: 106px;
      line-height: 0.75;
      width: 29px;
      height: 24px;
    }

    .btn {
      top: 419px;
      left: 105px;
      width: 128px;
      height: 40px;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
    height: auto;
  }
  @media screen and (max-width: 832px) {
    height: 75px;
    img {
      width: 80px;
    }
  }
`;

export default ErrorPage;
